@charset 'utf-8';

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WavvePADO-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/WavvePADO-Regular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}
// 폰트패밀리 변수
// $faRegular: 'fa-regular', sans-serif;
// $faBrands: 'fa-brands', sans-serif;
// $faSolid: 'fa-solid', sans-serif;
// $icoMoon: 'icomoon', sans-serif;
// $theMify: 'themify', sans-serif;
$preReg: 'Pretendard-Regular', sans-serif;
$jalNan: 'JalnanGothic', sans-serif;
$wavveP: 'WavvePADO-Regular', sans-serif;


@mixin respond-to($breakpoint) {
  @if $breakpoint == 'thin' {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $breakpoint == 'small' {
    @media (min-width: 390px) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'big' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 90px;
  background: transparent;
  color: var(--whiteColor);
  border-bottom: 1px solid #e7e7e7;
  z-index: 999;


  @include respond-to('thin') {
    height: 84px;
    padding: 0 10px;
  }

  @include respond-to('small') {
    height: 84px;
    padding: 0 20px;
  }

  @include respond-to('desktop') {
    height: 90px;
  }

  &__inner {
    position: relative;
    width: inherit;
    height: inherit;
    max-width: 1760px;
    margin: 0 auto;

    &__contentBox {
      background: transparent;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logoBox {
        // height: 100px;
        .mainLogo {
          line-height: 30px;
          font-weight: 700;

          .linkFlex {
            @include respond-to('big') {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .header__logo {
            line-height: 40px;
            background: url(./images/ione_main_logo.png) no-repeat center/cover;

            @include respond-to('thin') {
              width: 50px;
              height: 50px;
              filter: brightness(100);
            }

            @include respond-to('small') {
              width: 64px;
              height: 60px;
            }
            @include respond-to('big') {
              width: 74px;
              height: 70px;
            }
          }

          span {
            font-size: 1.5rem;
            margin-left: 1rem;
            font-family: $wavveP;

            @include respond-to('thin') {
              display: none;
            }
            @include respond-to('desktop') {
              display: block;
            }
          }
        }
      }

      .nav {
        &__list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;

          @include respond-to('thin') {
            display: none;
          }
          @include respond-to('desktop') {
            display: flex;
            justify-content: space-around;
            margin-top: -5px;
          }

          li {
            margin: 0 2rem;

            .gnbText {
              display: block;
              color: #ddd;
              font-size: 1.3rem;
              font-weight: 700;
              font-family: $wavveP;

              @include respond-to('desktop') {
                font-size: 1rem;
                align-items: center;
              }
              @include respond-to('big') {
                font-size: 16px;
                font-weight: 500;
              }

              &:hover {
                color: #ffffff;
                transition: color 0.7s;
              }
            }
          }

          &-langBox {
            font-size: 20px;
            cursor: pointer;
            font-family: $wavveP;

            @include respond-to('desktop') {
              display: none;
            }
            @include respond-to('big') {
              display: none;
              margin-left: 100px;
            }

            &-langKor {
              position: relative;
              margin: 0 10px;

              &::after {
                content: '';
                display: inline-block;
                width: 4px;
                height: 15px;
                margin-left: 10px;
                background-color: #dadada;
              }
              &.active {
                color: #fff;

                &::before {
                  content: '';
                  position: absolute;
                  top: -15px;
                  margin-left: 10px;
                  width: 10px;
                  height: 10px;
                  background: crimson;
                  border-radius: 100%;
                }
              }
            }
            &-langEng {
              color: #ececec;
            }
          }
        }
      }
      .mobMenuBtn {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: -10px;
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 1000;
        transform: scaleX(-1);

        @include respond-to('desktop') {
          display: none;
        }

        & .mobMenuBtn__line {
          // 수정된 부분
          width: 25px;
          height: 3px;
          background: #fff;
          transition: transform 0.5s ease;

          &:nth-child(2) {
            margin: 5px 0;
            width: 15px;
          }
        }

        &.open {
          transition: transform 0.5s ease;

          & .mobMenuBtn__line:nth-child(1) {
            // 수정된 부분
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: center;
          }
          & .mobMenuBtn__line:nth-child(2) {
            // 수정된 부분
            opacity: 0;
          }
          & .mobMenuBtn__line:nth-child(3) {
            // 수정된 부분
            transform: rotate(-45deg);
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: center;
          }
        }
      }
    }
  }
  .mobNav {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 815px;
    padding: 124px 20px 0;
    background: #4e5254;
    text-align: center;
    z-index: -1;
    transition: left 0.5s ease;

    @include respond-to('desktop') {
      display: none;
    }

    &.open {
      left: 0;
    }

    // 모바일 언어 이벤트 스타일
    &__langBox {
      position: relative;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &-langKor.active {
        color: #fff;
        font-weight: 900;

        &::before {
          content: '';
          position: absolute;
          top: -20px;
          margin-left: 10px;
          width: 10px;
          height: 10px;
          background: crimson;
          border-radius: 100%;
        }

        &::after {
          content: '';
          display: inline-block;
          width: 1px;
          height: 15px;
          margin: 0 10px;
          background: #fff;
        }
      }
      &-langEng {
        color: #bab4b4;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 45px;

      li {
        margin: 20px 0;
        text-align: left;

        &::after {
          content: '';
          display: block;
          margin-top: 1.5rem;
          width: 100%;
          background: #ccc;
          border-bottom: 2px solid #eee;
        }

        a {
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }

    // .socialGroup {
    //   width: 100%;
    //   background: red;

    //   &__list {
    //     display: flex;

    //     li {

    //       &:nth-of-type(1) {
    //         background: url(./images/);
    //       }
    //     }
    //   }
    // }
  }

  &.scrolled {
    height: 90px;
    background: #2348a7f6;
    transition: background 0.5s;
    z-index: 9999;

    @include respond-to('thin') {
      height: 84px;
      border-bottom: unset;
    }

    @include respond-to('big') {
      height: 90px;
    }

    .gnbText {
      

      @include respond-to('thin') {
        color: #eee;
        font-weight: 600;
      }
      @include respond-to('tablet') {
        color: #444;
        font-weight: 400;
      }
    }

    .gnbText:hover {
      color: #ffffff;
      transition: color 0.2s;
    }
  }
}
